@font-face {
  font-family: "Popins-bold";
  src: url("../styles/fonts/Poppins-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Popins-regular";
  src: url("../styles/fonts/Poppins-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  background-color: #fafafa;
  font-family: "Popins-bold", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Popins-bold", sans-serif;
  box-sizing: border-box;
}


button {
  appearance: none;
  background: none;
  border: none;
  outline: none;
}

form {
  display: block;
  position: relative;
}

form:after {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  bottom: -5px;
  z-index: 1;
  background-image: linear-gradient(to bottom right, #FFCE00, #FE4880);
}

form .form-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f6f6f6;
  padding: 30px;
  border-radius: 15px;
  box-shadow: rgba(7, 65, 115, 0.3) 0px 6px 35px 0px;
  margin-top: 5rem;
  margin-bottom: 2rem;
}

form .form-inner h2 {
  color: #4D4D4D;
  font-size: 18px;
  margin-bottom: 5px;
  font-family: "Popins-regular", sans-serif;
}

form .form-inner h4 a{
  color: #20254C;
  font-size: 16px;
  text-decoration: none;
  font-weight: 500;
  padding: 6px;
}

form .form-inner .form-group {
  display: block;
  padding: 10px;
}

.login-navbar-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  width: 100%;
  padding: 10px;
  padding-right: 40px;
}

.login-navbar-container h5{
  color: #52519B;
  font-size: 14px;
  font-family: "Popins-regular", sans-serif;
}

.login-navbar-options{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.login-navbar-options-home{
  color: #52519B;
  font-family: "Popins-regular", sans-serif;
  text-decoration: none;
  padding: 5px;
  margin-right: 70%;
}

.login-navbar-options-home a{
  color: #52519B;
  font-family: "Popins-regular", sans-serif;
  text-decoration: none;
}

.login-navbar-btn{
  background-color: white;
  border-color: #52519B;
  border-width: 2px;
  border-style: solid;
  color: #52519B;
  text-decoration: none;
  border-radius: 15px;
  padding: 10px;
  margin-right: 10px;
  cursor: pointer;
  transition: 0.4s;
}

.login-navbar-btn:hover{
  background-color: #52519B;
  color: #fafafa;
}

.login-navbar-btn a{
  color: #52519B;
  font-size: 14px;
  font-family: "Popins-regular", sans-serif;
  text-decoration: none;
}

.login-navbar-btn:hover a{
  color: #fafafa;
}

.form-group-child{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
} 

.form-inner .form-group label {
  display: block;
  color: #666;
  font-size: 12px;
  transition: 0.4s;
}

.form-inner .form-group:focus-within label {
  color: #20254C;
}

.form-inner .form-group input {
  display: flex;
  padding: 12px 10px 10px 5px;
  background-color: #f6f6f6;
  border-radius: 10px;
  border-color: #52519B;
  border-width: 0.1px;
  outline: none;
  margin-top: 3px;
  width: 17rem;
  height: auto;
  color: #4D4D4D;
  font-family: "Popins-regular", sans-serif;
  font-size: 15px;
}

form .form-inner input[type="submit"],
.welcome button {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 8px;
  background-image: linear-gradient(to right, #52519B 30%, #20254C 50%, #52519B);
  background-size: 200%;
  background-position: 0%;
  transition: 0.4s;
  color: #FFF;
  font-weight: 700;
  cursor: pointer;
  border: none;
  width: 100%;
}

form .form-inner input[type="submit"]:hover,
.welcome button:hover {
  background-position: 100% 0%;
  box-shadow: rgba(7, 65, 115, 0.3) 0px 6px 35px 0px;
}

.error-box {
  padding: 2px 2px 20px 10px;
  font-size: 13px;
}

.error {
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #ff726f;
  width: 100%;
  max-width: 15rem;
  height: auto;
  border-radius: 5px;
  padding: 5px;
}

.logo {
  width: 100%;
  justify-content: center;
}

.logo img {
  width: 300px;
  height: auto;
}

.persistCheck {
  font-size: 0.75rem;
  display: flex;
  margin-bottom: 20px;
}

.persistCheck label {
  margin-top: 1px;
  width: 100%;
  color: #A8A8A8;
}

[type="checkbox"] {
  height: 20px;
  width: 20px;
  background-color: #A8A8A8;
  border-radius: 20px;
  margin-right: 5px;
}

[type="checkbox"]:checked {
  height: 20px;
  width: 20px;
  background-color: #52519B;
}

.show-password-btn{
    position: absolute;
    right: 20px;
    top: 60%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    color: grey;
  }

@media screen and (max-width: 1200px) {
  
  .login-navbar-options-home{
    margin-right: 50%;
  }

}

@media screen and (max-width: 790px) {
  
  .login-navbar-options-home{
    margin-right: 30%;
  }

}

@media screen and (max-width: 600px) {
  
  .login-navbar-options-home{
    margin-right: 20%;
  }

  .login-navbar-btn a{
    font-size: 12px;
  }

  .login-navbar-btn{
    padding: 10px;
  }

  .login-navbar-container h5{
    font-size: 12px;
  }

}

.forgot-password-btn {
  color: #20254C;
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;
  padding: 6px;
}

.forgot-password-btn:hover {
  color: #52519B;
  cursor: pointer;
}