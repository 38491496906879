

.form-inner {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-inner div {
  display: flex;
  padding-bottom: 15px;
}

.error-box, .success-box {
  margin-bottom: 15px;
}

input[type="password"],
input[type="text"] {
  width: 100%;
  padding-right: 40px; /* Espacio para el botón del ojo */
  box-sizing: border-box;
  height: 40px; /* Asegura que el input tenga una altura consistente */
  border-radius: 5px;
  border: 1px solid #ccc;
  padding-left: 10px;
}

.show-password-btn {
  display: flex;
  border: none;
  cursor: pointer;
  margin-top: 3px;
}

.show-password-btn:focus {
  outline: none;
}

input[type="submit"] {
  background-color: #4a4e69; /* Color de fondo del botón */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

input[type="submit"]:hover {
  background-color: #3b3e52;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

body {
  background-color: #f8f8f8; /* Fondo gris claro para toda la página */
}

.logo {
  width: 100%;
  justify-content: center;
}

.logo img {
  width: 300px;
  height: auto;
}

.success-box {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
}

.error-box {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  padding: 10px;
}