/* landingPage.css */

@font-face {
    font-family: "Popins-regular";
    src: url("../styles/fonts/Poppins-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}

body, html {
    margin: 0;
    padding: 0;
    font-family: "Popins-regular", sans-serif;
    height: 100%;
  }

  .demo-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background: linear-gradient(90deg, rgba(82, 81, 155) 0%, rgba(32, 37, 76) 100%);
  }
  
  .app {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }

  .demo-options{
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    width: 100%;
    padding: 20px;
  }

  .demo-options h2{
    color: white;
    font-size: 20px;
    margin-bottom: 20px;
    text-align: center;
    justify-content: center;
  }

  .demo-options h2:hover{
    color: #f5f5f5;
    cursor: pointer;
  }
  
  .form-container {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-container h4 {
    font-size: 15px;
    font-weight: normal;
    margin-bottom: 20px;
    padding: 15px;
  }
  
  .form input, .form textarea {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    font-family: "Popins-regular", sans-serif;
  }
  
  .form textarea {
    height: 100px;
    resize: none;
  }
  
  .form button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background: linear-gradient(90deg, rgba(82, 81, 155) 0%, rgba(32, 37, 76) 100%);
    color: white;
    font-size: 16px;
    cursor: pointer;
  }

  .notification {
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
  }
  
  .notification.success {
    background-color: #4caf50;
  }
  
  .notification.error {
    background-color: #f44336;
  }

  @media screen and (max-width: 768px){
    .form-container {
      width: 90%;
    }

    .form input, .form textarea {
      width: 100%;
      font-size: 12px;
    }

    .form button {
      width: 100%;
    }

    .form-container h4 {
      font-size: 12px;
      margin-bottom: 5px;
    }

    .notification {
      font-size: 14px;
    }

  }