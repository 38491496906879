.perfil-component{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.perfil-component h3 {
    font-size: 20px;
    color: #20254C;
    font-weight: lighter;

}

.perfil-component h2 {
    font-size: 22px;
    color: #52519B;
}

.perfil-component h1 {
    font-size: 27px;
    color: #20254C;
}

.cashflow-total{
    display: flex;
    flex-direction: row;
}

.cashflow-total h2 {
  display: flex;
    font-size: 20px;
    color: #52519B;
    font-weight: lighter;
}

.cashflow-total img {
    display: flex;
    height: 30px;
    width: auto;
    align-items: center;
}

.categorias-title{
    width: 100vw;
    height: auto;
    padding-left: 30%;
    margin-top: 2rem;
}

.categorias-title h4 {
    font-size: 12px;
}

.categorias-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 4rem;
  }
  
  .categorias-item {
    background-color: #f1f1f1;
    border-radius: 8px;
    padding: 8px;
    margin: 4px;
    flex: 0 1 calc(15% - 6px); /* Ajusta el porcentaje según el número de elementos que quieras por fila */
    box-sizing: border-box;
    text-align: center;
  }
  
  .categorias-item h4 {
    font-size: 10px;
    color: #52519B;
    margin: 0;
  }
  
  @media (max-width: 768px) {
    .categorias-container {
      flex-wrap: nowrap;
      overflow-x: auto;
    }
  
    .categorias-item {
      flex: 0 0 auto;
      margin: 4px;
    }
  }

  .recomendaciones-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 4rem;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    flex-direction: column;
    background-color: #b3b2d7;
    max-width: 50vw;
  }

  .recomendaciones-container h3 {
    font-size: 20px;
    color: #20254C;
    font-weight: lighter;
  }

  .recomendaciones-container h4 {
    font-size: 12px;
    color: #52519B;
  }

  .recomendaciones-container button {
    background-color: #52519B;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 8px 16px;
    margin: 8px;
    cursor: pointer;
    max-width: 16rem;
  }

  