.home-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

h3 {
    color: #52519B;
    font-size: 27px;
    margin-bottom: 5px;
    font-weight: normal;
    padding: 0px 10px 0px 10px;
    margin: 0px;
}

h4 {
    color: #343434;
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: normal;
    padding: 0px;
    margin: 0px;
}

h5 {
    color: #52519B;
    font-size: 15px;
    margin-bottom: 10px;
}

h6 {
    color: grey;
    font-size: 10px;
    margin-bottom: 2px;
    padding: 0px ;
    margin: 0px;
}

p {
    color: #52519B;
}

.cuentas-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.cuentas-container-grid {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
}

.cuenta-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 10px;
    width: 100%;
    height: auto;
    max-width: 25rem;
    box-shadow: rgba(7, 65, 115, 0.3) 0px 6px 15px 0px;
    padding: 1rem;
    margin: 5px;
}

.cuentas-container-grid :hover {
    background-color: #52519B;

    h4 {
        color: #f0f0f0;
    }

    h3 {
        color: #f0f0f0;
    }

    h6 {
        color: #f0f0f0;
    }

    cursor: pointer;
}

.cuenta-container-inner {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.cuenta-container-inner h4, h5 {
    margin: 0;  
}

.cuenta-container-monto{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 5px;
}

.cuenta-container-monto h4 {
    margin: 2px;
}

/* Animación de entrada */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  /* Animación de salida */
  @keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
  }

.modal-backdrop.fadeOut {
    animation: fadeOut 0.2s ease-out forwards;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Fondo oscuro semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.2s ease-out forwards; /* Aplica la animación de entrada */
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    z-index: 1000;
  }
.crear-cuenta-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    padding-top: 3rem;
}

.crear-cuenta-container button {
    background-color: #52519B;
    color: #f0f0f0;
    border: none;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.formulario-crear-cuenta {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 10px;
    padding-left: 8%;
    padding-right: 8%;
}

.formulario-crear-cuenta input, select {
    display: flex;
    width: 100%;
    padding: 8px 0px 10px 5px;
    background-color: #f6f6f6;
    border-radius: 4px;
    border-color: #52519B;
    border-width: 0.1px;
    outline: none;
    width: 100%;
}

.crear-cuenta-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
    width: 100%;
}

.crear-cuenta-item select {
    padding: 7px;
    border-radius: 7px;
    border: 1px solid #52519B;
    background-color: #f0f0f0;
    width: 100%;
}

.crear-cuenta-item label {
    color: #52519B;
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: bold;
}

.tipo-moneda-container{
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.crear-cuenta-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
}

.crear-cuenta-btn button{
    width: 100%;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    margin: 5px
}

.cancelar-crear-cuenta-btn button {
    background-color: transparent;
    color: #52519B;
    border: none;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    margin: 5px;
}

.cuenta-component {
    padding: 20px;
}

.back-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #52519B; 
    border: none; /* Elimina el borde */
    cursor: pointer; /* Cambia el cursor a un puntero */
    width: 3rem;
    height: 3rem;
    padding: 5px;
    border-radius: 5px;
    box-shadow: rgba(7, 65, 115, 0.3) 0px 6px 15px 0px;
    transition: 0.5s;
}

.back-button img {
    width: 50%;
    height: auto;
}

.back-button:hover {
    background-color: #0056b3;
    transform: scale(1.07);
}   

.cuenta-details {
    margin-top: 20px;
    display: flex;
    margin-left: 20%;
    margin-right: 20%;
    flex-direction: column;
}

.cuenta-details-cuenta{
    display: flex;
    flex-direction: column;
}
.cuenta-details-cuenta h5, h3{
    margin: 0px;
    padding: 0px 0px 0px 0px;
}

.cuenta-details-saldo{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cuenta-details-saldo h5 {
    margin: 0px;
    padding: 0px 0px 0px 0px;
}

.cuenta-details-saldo h1 {
    margin: 0px;
    padding: 0px 0px 0px 0px;
    color: #20254C;
}

.cuenta-details-movimientos{
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
}

.cuenta-details-add-movimiento{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
}

.cuenta-details-add-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}

.cuenta-details-add-ganancia{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    border-width: 0px;
    background-color: #23C552;
    height: 4rem;
    width: 4rem;
    color: white;
}

.cuenta-details-add-ganancia h5{
    margin: 0px;
    padding: 0px 0px 0px 0px;
}

.cuenta-details-add-gasto{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    border-width: 0px;
    background-color: #F84F31;
    height: 4rem;
    width: 4rem;
    color: white;
}

.cuenta-details-add-gasto h5{
    margin: 0px;
    padding: 0px 0px 0px 0px;
}

.cuenta-details-add-ganancia h1{
    font-size: 3rem;
}

.cuenta-details-add-gasto h1{
    font-size: 3rem;
}

.cuenta-details-add-ganancia:hover{
    cursor: pointer;
}

.cuenta-details-add-gasto:hover{
    cursor: pointer;
}

.button-cuenta {
    margin: 10px;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.button-cuenta:hover {
    background-color: #0056b3;
}

.no-cuentas-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    text-align: center;
}

.no-cuentas-container p{
    color: #52519B;
    font-size: 20px;
    font-weight: bold;
    padding: 2rem;
}

.image-no-cuentas{
    width: 20%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.25;
}

.movimiento-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%;
}

.cuenta-formulario-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%;
}

.cuenta-formulario-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0.8rem;
}

.cuenta-formulario-item input{
    padding: 8px 0px 10px 5px;
    background-color: #f6f6f6;
    border-radius: 4px;
    border-color: #52519B;
    border-width: 0.1px;
    outline: none;
    height: auto;
    width: 100%;
    max-width: 15rem;
}

.cuenta-formulario-item label{
    color: white;
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: bold;
}

.cuenta-formulario-item button{
    background-color: #52519B;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    margin: 5px;
}

.agregar-movimiento-cancelar{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
}

.agregar-movimiento-cancelar button{
    width: 100%;
    color: red;
    border: none;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    margin: 5px;
}

.perfil-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    padding-top: 3rem;
}

.missing-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    padding-top: 3rem;
}

.animation-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    padding-top: 3rem;
}

.missing-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    background-color: #52519B;
    color: white;
    width: 100%;
    height: 4rem;
    max-width: 15rem;
    border-radius: 5px;
    box-shadow: rgba(7, 65, 115, 0.3) 0px 6px 15px 0px;
    transition: 0.5s;
    margin-top: 15px;
}

.missing-btn:hover{
    transform: scale(1.05);
    cursor: pointer;
}

.missing-btn a{
    color: white;
}

.movimientos-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    padding-top: 0.5rem;
}

.movimiento-item-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 10px;
    margin: 5px;
    box-shadow: rgba(7, 65, 115, 0.3) 0px 6px 15px 0px;
}
.movimiento-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.movimiento-item button {
    color: #20254C;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;
}

.movimiento-item-form{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: 100%;
}

.movimiento-item-form button {
    color: #20254C;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;
}

.movimiento-item-form input, select {
    padding: 8px 0px 8px 5px;
    background-color: #f6f6f6;
    border-radius: 7px;
    border-color: grey;
    border-width: 0.1px;
    outline: none;
    height: auto;
    width: 100%;
    max-width: 15rem;
    margin: 5px;
}

.grafico-row-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60vh;
    align-items: center;
    justify-content: center;
}

.graficos-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 35vw;
    padding: 5px;
}

@media screen and (max-width: 1000px){
    .graficos-container{
        width: 45vw;
    }  
}

@media screen and (max-width: 850px){
    .graficos-container{
        width: 50vw;
    }  
}

@media screen and (max-width: 600px){
    .grafico-row-container{
        display: flex;
        flex-direction: column;
        height: 100%;
        width: auto;
        align-items: center;
    }

    .graficos-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: auto;
        min-height: 3rem;
        padding: 3rem;
    }
}

@media screen and (max-width: 1100px) {
    .movimiento-item-form h5 {
        font-size: 13px;
    }

    .cuenta-details {
        margin-left: 15%;
        margin-right: 15%;
    }
}

@media screen and (max-width: 850px) {
    .cuenta-details {
        margin-left: 10%;
        margin-right: 10%;
    }

    .movimiento-item-form h5 {
        font-size: 12px;
    }

}

@media screen and (max-width: 750px) {
    .movimiento-item-form h5 {
        font-size: 10px;
    }
}

@media screen and (max-width: 650px) {

    .cuenta-details {
        margin-left: 5%;
        margin-right: 5%;
    }

    .movimiento-item-form h5 {
        font-size: 10px;
    }
}

@media screen and (max-width: 600px) {
    .movimiento-item-form h5 {
        font-size: 9px;
    }  
}

@media screen and (max-width: 500px) {
    .movimiento-item-form{
        padding: 6px;
        width: 100%;
    }

    .movimiento-item-form h5 {
        font-size: 7.5px;
    } 

    .movimiento-item-form button {
        font-size: 10px;
    }
}

@media screen and (max-width: 400px) {
    .movimiento-item-form h5 {
        font-size: 6px;
    }
    .movimiento-item-form button {
        font-size: 8px;
    }
}

@media screen and (max-width: 400px) {
    .movimiento-item-container{
        height: 7rem;
        width: 100%;
        padding: 5px;
        border-radius: 10px;
    }

    .movimiento-item-form{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 7px;
        width: 100%;
    }
    .movimiento-item-form h5 {
        font-size: 11px;
    }
    .movimiento-item-form button {
        font-size: 13px;
    }
}

@media screen and (max-width: 250px) {

    .cuenta-details {
        margin-left: 2%;
        margin-right: 2%;
    }

    .movimiento-item-form h5 {
        font-size: 8px;
    }
    .movimiento-item-form button {
        font-size: 10px;
    }
}

@media screen and (max-width: 800px) {
   .cuenta-container {
        max-width: 20rem;
    }

    .cuenta-container-inner h3 {
        font-size: 18px;
    }
    .cuenta-container-inner h6 {
        font-size: 7px;
    }

    .cuenta-container-monto h4 {
        font-size: 12px;
    }

  }

  @media screen and (max-width: 600px) {

    .cuenta-details-cuenta h3 {
        font-size: 20px;
    }

    .cuenta-details-cuenta h5 {
        font-size: 12px;
    }

    .cuenta-details-saldo h1 {
        font-size: 2rem;
    }
}

.banner-home-btn{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.ojo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.ojo img {
    width: 20px;
    height: auto;
}

.cuenta-container-monto.blurred {
    filter: blur(10px);
}

.blurred {
    filter: blur(8px);
    transition: filter 0.3s ease-in-out;
  }