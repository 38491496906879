.AppLanding {
    min-height: 100vh;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, rgba(82, 81, 155) 0%, rgba(32, 37, 76) 100%);
    flex-direction: column;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Popins-bold', sans-serif;
}

@font-face {
    font-family: "Popins-bold";
    src: url("../styles/fonts/Poppins-Bold.ttf");
    font-weight: bold;
    font-style: normal;
}

h1 {
    margin-bottom: 5px;
    font-size: 3.2rem;
    color: white;
    line-height: 4.2rem;
}

.navbar-landing {
    display: flex;
    justify-content: end;
    align-items: center;
    background: linear-gradient(90deg, rgba(82, 81, 155) 0%, rgba(32, 37, 76) 100%);
    padding: 20px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-landing-logo {
    color: white;
    font-size: 24px;
    font-weight: bold;
    margin-right: auto;
}

.navbar-landing-logo img {
    width: 180px;
    height: auto;
}

.navbar-landing-links {
    list-style: none;
    display: flex;
    gap: 20px;
    margin: auto;
    padding: 0;
}

.navbar-landing-links li {
    display: inline;
    padding: 10px;
}

.navbar-landing-links a {
    color: white;
    text-decoration: none;
    font-size: 18px;
}

.navbar-landing-btn-container {
    display: flex;
    flex-direction: row;
}

.navbar-landing-btn {
    display: flex;
    background-color: transparent;
    border: 2px solid white;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 1rem;
}

.navbar-landing-btn:hover {
    background-color: white;
    color: #20254C;
}

.navbar-landing-menu {
    display: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}

.navbar-landing-menu span {
    display: none;
    width: 25px;
    height: 3px;
    margin: 3px;
    background: white;
}

.close-btn {
    display: none;
}

.close-btn.open {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    margin-top: 5px;
    border-radius: 2px;
}

.close-btn div {
    display: flex;
    background-color: tomato;
    height: auto;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}

.content {
    padding-top: 5px;
    flex-direction: column;
}

.hero-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 95vh;
}

.hero-section h2 {
    margin-bottom: 20px;
    font-size: 2em;
}

.hero-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.hero {
    width: 100%;
    max-width: 400px;
    text-align: center;
}

.hero-title {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    height: 100%;
    max-width: 600px;
    text-align: left;
    justify-content: center;
    align-items: center;
}

.hero-title p {
    margin-bottom: 20px;
    font-size: 1em;
    text-align: center;
    color: #a9a4cd;
}

.hero img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.hero-btn {
    background-color: transparent;
    border: 2px solid white;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 5rem;
    width: 100%;
    max-width: 200px;
    text-align: center;
    font-size: 16px;
}

.hero-btn:hover {
    background-color: white;
    color: #20254C;
}


@media (max-width: 1000px) {

    .navbar-landing-menu span {
        display: flex;
    }

    .navbar-landing-links {
        display: flex;
        flex-direction: column;
        background-color: rgba(0, 0, 0, 0.9);
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        width: 250px;
        padding: 25px;
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
    }

    .navbar-landing-links.open {
        transform: translateX(0);
    }

    /*.navbar-landing-btn {
        display: none;
    }*/

    .hero-container {
        flex-direction: column !important;
        gap: 20px;
    }

    .hero-title {
        padding: 10px;
        width: 100%;
        height: auto;
        max-width: 600px;
        padding-top: 10rem;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .hero-title button {
        margin-top: 20px;
    }

    .hero-title h1 {
        margin-bottom: 5px;
        font-size: 2.6rem;
        color: white;
        line-height: 3.2rem;
        padding: auto;
    }

    .hero {
        padding: 40px;
    }
}

@media screen and (max-width: 800px) {

    .hero-title {
        padding: 10px;
        width: 100%;
        height: 100%;
        max-width: 600px;
        margin-top: 8rem;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .hero-title h1 {
        margin-bottom: 5px;
        font-size: 2.6rem;
        color: white;
        line-height: 3.2rem;
        padding: 7px;
    }

    .hero-title button {
        margin-top: 35px;
    }
}

@media screen and (max-width: 600px) {
    .hero-title {
        padding: 10px;
        width: 100%;
        height: 100%;
        max-width: 600px;
        margin-top: 10rem;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .hero-title p {
        font-size: 0.8em;
    }

    .hero-title h1 {
        font-size: 2.2rem;
        line-height: 2.8rem;
    }

    .hero-title button {
        margin-top: 20px;
    }
}

@media screen and (max-width: 500px) {
    .hero-title {
        padding: 10px;
        width: 100%;
        height: 100%;
        max-width: 600px;
        margin-top: 10rem;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .hero-title h1 {
        font-size: 2rem;
        line-height: 2.6rem;
    }

    .hero-title button {
        margin-top: 20px;
    }

    .navbar-landing-logo img {
        width: 150px;
        height: auto;
    }

    .navbar-landing-btn {
        padding: 10px 15px;
    }

}

@media screen and (max-width: 400px) {
    .hero-title {
        padding: 10px;
        width: 100%;
        height: 100%;
        max-width: 600px;
        margin-top: 10rem;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .navbar-landing-logo img {
        width: 110px;
        height: auto;
    }

    .hero-title h1 {
        font-size: 1.8rem;
        line-height: 2.4rem;
    }

    .navbar-landing-btn {
        padding: 8px 15px;
        font-size: 12px;
    }

}

@media screen and (max-width: 350px) {
    .hero-title {
        padding: 10px;
        width: 100%;
        height: 100%;
        max-width: 600px;
        margin-top: 10rem;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .navbar-landing-logo img {
        width: 95px;
        height: auto;
        padding-left: 7px;
        padding-right: 7px;
    }

    .hero-title h1 {
        font-size: 1.6rem;
        line-height: 2.2rem;
    }

    .navbar-landing-btn {
        padding: 8px 15px;
        font-size: 10px;
    }

}

.cookie-consent-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #20254C;
    color: #FFFFFF;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    text-align: center;
}

.cookie-consent-banner p {
    margin: 0 0 10px;
    font-size: 16px;
    color: #999999;
}

.cookie-consent-banner button {
    background-color: #52519B;
    /* Fondo Dorado */
    color: #FFFFFF;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.aceptar:hover {
    background-color: #50c878;
    /* Cambia a Rojo Oscuro en hover */
}

.rechazar:hover {
    background-color: #8B0000;
    /* Cambia a Rojo Oscuro en hover */
}

@media (min-width: 600px) {
    .cookie-consent-banner {
        flex-direction: row;
        justify-content: space-between;
        padding: 15px 30px;
    }

    .cookie-consent-banner p {
        margin: 0;
        font-size: 18px;
    }

    .cookie-consent-banner button {
        font-size: 18px;
    }
}

.services-section {
    padding: 10px 0px;
}

.services-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
    padding-bottom: 3rem;
}

.service {
    flex: 1;
    min-width: 250px;
    max-width: 300px;
    text-align: center;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service img {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.service h2 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
}

.service p {
    font-size: 1rem;
    color: #666;
}

.footer-section {
    background-color: #20254C;
    color: white;
    padding: 60px 20px;
    position: relative;
    text-align: center;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    margin-top: 40px;
    width: 100%;
}

.footer-container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.footer-logo img {
    width: 150px;
    height: auto;
    margin-bottom: 20px;
}

.footer-bottom {
    margin-top: 20px;
    font-size: 0.9rem;
}