.navbar {
    background-color: #fafafa;
    color: #52519B;
    display: flex;
    flex-direction: column;
    align-items: center !important;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    box-shadow: rgba(7, 65, 115, 0.15) 0px 6px 35px 0px;
}

.nav-header {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu-icon {
    color: #52519B;
    cursor: pointer;
}

.links {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
    max-height: 0; /* Initially hidden */
    padding-left: 10px;
    padding-right: 10px;
}

.links.open {
    max-height: 100px; /* Adjust based on your content */
}

.nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 15rem;
    text-align: center;
    margin-right: 5px;
    margin-left: 5px;
}

.nav-link a, .nav-link button {
    color: #52519B;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.nav-link button:hover {
    cursor: pointer;
}

.nav-text{
    font-family: "Popins-regular", sans-serif;
    font-size: 15px;
}

.navbar .navbar-icon{
    font-size: 25px;
    margin: 2px;
}

@media (min-width: 900px) {
    .navbar {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .menu-icon {
        display: none; /* Hide menu icon on desktop */
    }

    .links {
        flex-direction: row;
        max-height: none; /* Show all links on desktop */
    }

    .nav-link a, .nav-link button {
        justify-content: center; /* Center content in navbar links */
        padding: 0 15px; /* Space out links */
    }
}

.logo-navbar img{
    display: flex;
    height: 4rem;
    width: auto;
    align-items: center;
    padding: 1rem;
}

@media screen and (max-width: 900px) {
    .nav-link{
        margin: 10px;
    }

    .nav-text {
        font-size: 15px;
    }
}

@media screen and (max-width: 600px) {
    .nav-link{
        margin: 5px;
    }

    .nav-text {
        font-size: 10px;
    }
}