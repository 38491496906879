/* AdminTab.css */
.admin-tab {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .admin-tab h1 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
  }
  
  .section {
    margin-bottom: 30px;
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .section h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #555;
  }
  
  .section p {
    font-size: 1.2em;
    color: #666;
    margin: 0;
  }
  
  .section ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .section li {
    background: #f0f0f0;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s ease;
  }
  
  .section li:hover {
    background-color: #e0e0e0;
  }
  
  .section li span {
    font-weight: bold;
    color: #333;
  }
  